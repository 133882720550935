import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard')
        }
      ]
    },
    {
      path: '/applications',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Application',
          path: '',
          component: () => import('@/views/application/Application')
        },
        {
          name: 'New Application',
          path: 'new',
          component: () => import('@/views/application/NewApplication')
        }
      ]
    },
    {
      path: '/registry',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'ICPs',
          path: 'icps',
          component: () => import('@/views/registry/Icp')
        },
        {
          name: 'MeterReading',
          path: 'icps/:icpnum/readings',
          props: true,
          meta: {
            name: 'Meter Reading'
          },
          component: () => import('@/views/registry/Reading')
        }
      ]
    },
    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/pages/Login')
        }
      ]
    },
    {
      path: '*',
      component: () => import('@/views/pages/Error')
    }
  ]
})
