import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import registry from './modules/registry'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: null
  },

  mutations: {
    SET_DRAWER (state, payload) {
      state.drawer = payload
    }
  },

  actions: {},

  modules: {
    auth,
    registry
  }
})
