import Vue from 'vue'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
/* eslint-disable camelcase */
import { email, max, min, numeric, required, required_if } from 'vee-validate/dist/rules'

extend('email', email)
extend('max', max)
extend('min', min)
extend('numeric', numeric)
extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})
/* eslint-disable camelcase */
extend('required_if', required_if)

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
