import Vue from 'vue'

// components

// plugins
import './plugins/base'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'

import App from './App'
import router from './router'
import store from './store'
import axios from 'axios'

// Axios Settings
Vue.prototype.$http = axios
if ( process.env.NODE_ENV == 'production' ) {
  axios.defaults.baseURL = 'https://api.stackenergy.co.nz'
} else {
  axios.defaults.baseURL = 'http://api.stackenergy.co.nz.test:8000'
}
//axios.defaults.baseURL = process.env.STACK_API_SERVER
axios.defaults.headers.get.Accept = 'application/json'
axios.defaults.withCredentials = true

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
