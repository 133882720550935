import axios from 'axios'

export default {
  namespaced: true,

  state: {
    icps: []
  },

  getters: {
  },

  mutations: {
    SET_ICPS (state, icps) {
      state.icps = icps
    }
  },

  actions: {
    load_icp (context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/icps', { withCredentials: true })
          .then(response => {
            console.log(response)
            context.commit('SET_ICPS', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
